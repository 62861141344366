<template>
  <div
    class="container-btn-wrapper"
    v-b-tooltip.topleft.v-secondary.noninteractive="{
      title: tooltip_text ? tooltip_text : '',
      boundary: 'viewport',
    }"
  >
    <b-button
      :id="`btn-wrapper-${id_button}`"
      size="sm"
      ref="btn-toggle"
      variant="outline-secondary"
      :title="text_button"
      @click="handleClick"
      class="noprint btn-toggle"
      :class="custom_class"
      :disabled="disabled_button"
    >
      <b-icon icon="three-dots-vertical"></b-icon>
      <span v-if="text_button" class="ml-1">
        {{ text_button }}
      </span>
    </b-button>
    <b-popover
      :id="`popover-wrapper-${id_button}`"
      :target="`btn-wrapper-${id_button}`"
      placement="bottom"
      triggers="click blur"
      boundary="viewport"
    >
      <slot name="popover-content-wrapper" />
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "ButtonWrapperSmall",
  props: {
    id_button: {
      type: String,
      default: "",
    },
    text_button: {
      type: String,
      default: "",
    },
    tooltip_text: {
      type: String,
      required: true,
    },
    click_button: {
      type: Function,
      required: false,
    },
    size: {
      type: String,
      default: "sm",
    },
    // variant: {
    //   type: String,
    //   default: "secondary",
    // },
    show_icon: {
      type: Boolean,
      default: true,
    },
    custom_class: {
      type: String,
      default: "",
    },
    disabled_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleClick() {
      // Si se proporciona una función de click, se ejecuta
      if (!this.disabled_button) {
        if (this.click_button) {
          this.click_button();
        } else {
          this.$emit("click");
        }
      }
    },
  },
};
</script>

<style scoped>
.container-btn-wrapper {
  justify-self: center !important;
}
.btn-toggle {
  width: 23px !important;
  height: 23px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #bdbdbd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 0;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}
.btn-toggle:hover,
.btn-toggle:focus {
  background-color: #f7f9fa;
  color: #0056b3;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
</style>